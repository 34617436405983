import {
    PAGE_ID_PROSPECTS,
    PAGE_ID_AICPS,
    PAGE_ID_COMPANY_LOOKUP,
    PAGE_ID_SIGNAL_EXPLORER,
} from 'components/util/constants';
import {
    AICPS_URL,
    PROSPECTS_URL,
    COMPANY_LOOKUP_URL,
    SIGNAL_EXPLORER_URL,
} from 'components/util/routeConstants';

const navItems = [
    {
        id: PAGE_ID_SIGNAL_EXPLORER,
        href: SIGNAL_EXPLORER_URL,
        label: 'Signals',
    },
    {
        id: PAGE_ID_COMPANY_LOOKUP,
        href: COMPANY_LOOKUP_URL,
        label: 'Company Lookup',
    },
    {
        id: PAGE_ID_AICPS,
        href: AICPS_URL,
        label: 'aiCPs',
    },
    {
        id: PAGE_ID_PROSPECTS,
        href: PROSPECTS_URL,
        label: 'Prospects',
    },
];

const mainDesktopNavItems = navItems.map((navItem) => ({
    ...navItem,
    tooltipPlacement: 'right',
}));

const mainMobileNavItems = navItems.map((navItem) => ({
    ...navItem,
    tooltipPlacement: 'bottom',
}));

export {mainDesktopNavItems, mainMobileNavItems};
